import React, { useState, useRef } from "react";
import axios from "axios";
import "./Homepage.css";
import { useTheme } from "@mui/material/styles";
import teknofestLogo from "./assets/teknofest-logo.png";
import reactivaLogo from "./assets/reactiva-logo.png";
import rseaLogo from "./assets/rsea-logo.png";
import ClipboardJS from "clipboard";
import MuiAlert from "@mui/material/Alert";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import LabLogo from './assets/lab_logo.png';
import CircularProgress from "@mui/material/CircularProgress";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  Typography,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

//sonuclar tablosunun ileri geri butonları
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  //ilk sayfaya gider
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };
  //onceki sayfaya gider
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  //sonraki sayfaya gider
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  //son sayfaya gider
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      {/* ilk sayfaya giden buton */}
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      {/* bi onceki sayfaya giden buton */}
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      {/* sonraki sayfaya giden buton */}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      {/* son sayfaya giden buton */}
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

//sayfalama kisminin kullanacagi zorunlu degiskenler
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

//kullanılabilcek modeller (kullanilan bu degil)
const modelOptions = [
  { label: "Recon3D_BiGG", value: "Recon3D_BiGG" },
  { label: "Recon3D_VMH", value: "Recon3D_VMH" },
  { label: "Recon3D_VMH_large", value: "Recon3D_VMH_large" },
];

function Homepage() {
  const [data, setData] = useState(null);
  const [dataNotMatched, setDataNotMatched] = useState(null);
  //dosya olarak seçim yapılırsa kullanilan stateler
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  //yazili olarak girince kullanilan stateler
  const [text, setText] = useState("");
  const [modelText, setModelText] = useState("");
  const [checkboxes, setCheckboxes] = useState({});
  //true ise eslesen reaksiyonlar false ise eslesmeyen reaksiyonlar gosterilir
  const [isTrue, setIsTrue] = useState(true);
  const [isSuccess, setIsSuccess] = useState(null);
  const [matchesIndex, setMatchesIndex] = useState(0);
  const [inputCount, setInputCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedModel, setSelectedModel] = useState("");
  const [openLoading, setOpenLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [fileName1, setfileName1] = useState("");
  const [fileName2, setfileName2] = useState("");
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  // Avoid a layout jump when reaching the last page with empty rows. model
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //model name kısmı degisince caliscak kısım
  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (scrollType, rowIndex) => () => {
    setOpen(true);
    setScroll(scrollType);
    setMatchesIndex(rowIndex);
  };
  //loading iconunun acilip kapanmasi
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };

  const handleOpenLoading = () => {
    setOpenLoading(true);
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    const hedefElement = document.querySelector("#sonucKutusu");
        window.scrollTo({
          top : hedefElement.offsetTop,
          behavior : "smooth"
        })
    
  };
  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false); // Toggle the dialog open/close
  };
  const handleOpenErrorDialog = (newErrorText) => {
    setOpenErrorDialog(true); // Toggle the dialog open/close
    setErrorText(newErrorText || ""); // Set new error text
  };
  const uploadfileOnChange1 = (file) => {
    setSelectedFile1(file); // Toggle the dialog open/close
    setfileName1(file.name);
  };
  const uploadfileOnChange2 = (file) => {
    setSelectedFile2(file); // Toggle the dialog open/close
    setfileName2(file.name);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = React.useRef(null);

  const copyArrayToClipboard = (arr) => {
    const string = arr.join("\n");
    const textarea = document.createElement("textarea");
    textarea.style.position = "fixed";
    textarea.style.top = "-9999px";
    textarea.value = string;
    document.body.appendChild(textarea);
    textarea.select();

    const clipboard = new ClipboardJS(textarea);
    clipboard.on("success", () => {
      clipboard.destroy();
      document.body.removeChild(textarea);
    });

    clipboard.on("error", (e) => {
      console.error("Failed to copy array to clipboard:", e);
      clipboard.destroy();
      document.body.removeChild(textarea);
    });

    textarea.focus();
    document.execCommand("copy");
  };

  const handleCheckboxChange = (event) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if at least one checkbox is checked
    const selectedCheckboxes1 = Object.values(checkboxes);
    const isAnyCheckboxChecked = selectedCheckboxes1.includes(true);

    if (!isAnyCheckboxChecked) {
      handleOpenErrorDialog("Error ! Select at least one multiple test");
      return;
    }

    let formData = new FormData();
    let wordSize = 0; // Variable to hold the word size
    console.log(selectedModel);

    if (text) {
      // Get the value of the text area
      const textareaValue = text.trim();
      // Count the number of words
      wordSize = textareaValue.split(/\s+/).length;
      setInputCount(wordSize);
      const blob = new Blob([text], { type: "text/plain" });
      formData.append("input_rx_file", blob, "input_rx_file.txt");
    } else if (selectedFile1) {
      formData.append("input_rx_file", selectedFile1);
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        // Split content by new line to count lines
        const lines = content.split(/\r\n|\r|\n/);
        setInputCount(lines.length);
      };
      reader.readAsText(selectedFile1);
    }

    if (modelText) {
      // Get the value of the text area
      // const textareaValue = text.trim();
      // Count the number of words
      // wordSize = textareaValue.split(/\s+/).length;
      // setInputCount(wordSize);
      const blob = new Blob([modelText], { type: "text/plain" });
      formData.append("model_rx_file", blob, "model_rx_file.txt");
    } else if (selectedFile2) {
      formData.append("model_rx_file", selectedFile2);
    }

    let selectedCheckboxes = Object.keys(checkboxes).filter(
      (key) => checkboxes[key]
    );
    if (selectedCheckboxes.length > 0) {
      formData.append("checkboxes", JSON.stringify(selectedCheckboxes));
    }

    if (selectedModel !== "") {
      formData.append("model", selectedModel);
    }

    handleOpenLoading();
    //apiden verilerin alınması ve data state'ine yüklenmesi
    //eşleşmeyen datalar da burda yüklenir
    axios
      .post("https://rseatool.com/api/uploadfile/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setData(response.data["result_table_dict"]);
        setDataNotMatched(response.data["not_matched_rx_lst"]);
        setIsSuccess(response.data["success"]);
        handleCloseLoading();
        handleClickOpenDialog();
        
      })
      .catch((error) => {
        console.error(error);
        handleCloseLoading();
        handleClickOpenDialog();
      });
    setfileName1("");
    setfileName2("");
  };

  //sitenin ornek kullanımı icin calisan butonun işlemleri
  const handleOrnekKopyalama = () =>{
    setText(`25HVITD2tin
AHCYStr
AKGtp
AMPDA
ARACHDtr
ARTPLM1
ATPtx
CBPPer
CBPter
CDIPTr
CITtbm
COAtm
DAGK_hs
DGK2m
DGNSKm
DGSNtm
DM_hretn_n_
DNDPt11m
DNDPt9m
EICOSTETt
EX_11_cis_retfa[e]
EX_4nph[e]
EX_adrn[e]
EX_amp[e]
EX_arach[e]
EX_arachd[e]
EX_chsterol[e]
EX_clpnd[e]
EX_creat[e]
EX_crvnc[e]
EX_dag_hs[e]
EX_dgchol[e]
EX_h2o2[e]
EX_hexc[e]
EX_mthgxl[e]
EX_pe_hs[e]
EX_pglyc_hs[e]
EX_ptdca[e]
EX_tethex3[e]
EX_tetpent3[e]
EX_tmndnc[e]
EX_xylt[e]
FACOAL170
FACOAL2042
FACOAL244_1
FRDPtc
FUMtm
GCHOLAte
GLCter
GLYtm
GULLACter
H2Oter
HMGCOAtm
HPDCAt
HRETNtn
Htg
Htx
LEUt4
MMMm
MMTSADm
MTHGXLt
NDPK8m
O2ter
O2tm
PIPLC
PItx
PROSTGI2t
PROtm
PSt3
PVD3
RTOTALt
sink_citr[c]
SUCCt2m
SUCCt4_2
SUCD1m
SUCOAS1m
SUCOASm
TAGt
TCHOLAte
TETTET6t
THMPPtm
TMDPP
TRDR
UMPK6
UROLACer
VD3
EX_ahdt[e]
EX_dhap[e]
EX_fad[e]
r0002
r0165
r0179
r0196
r0480
r0488
r0512
r0517
r0587
r0788
r0789
r0801
r0842
r0860
r0974
r0986
r1078
r1116
r1117
r1156
r1291
r1431
r2508
r2521
RE0452M
RE0583C
RE1447N
RE1448N
RE1709C
RE1897C
RE1898C
RE1903R
RE1933C
RE1978C
RE2080C
RE2130C
RE2660C
RE2849C
RE3103C
RE3104C
RE3352C
RE3524R
EX_crm_hs[e]
PIt8
DDECE1CRNe
DOCOSACTDe
EX_doco13ac[e]
EX_docosac[e]
FAOXC5C5OHm
HOCTDEC2CRNe
4ABUTtcn
DM_4abut[n]
EX_4hpro[e]
EX_tdechola[e]
EX_pan4p[e]
EX_ptth[e]
FRDPtcr
G6PDH2c
GLYCTDle
MDHx
NADtx
PGLc
SBT_Dtde
THMDt5le
3MOBte
3MOPte
ANTHte
DM_Lcystin
DM_anth
EX_ahcys[e]
MAL_Lte
KYNATEtr2
CITRtr
FUMtr
XMPtr
3PGtr
IMPtr
EX_2pg[e]
EX_cholp[e]
EX_dcmp[e]
EX_ethamp[e]
EX_hcys_L[e]
EX_udpglcur[e]
EX_pmtcrn[e]
ADPOHc
3MHISc
TTDCRNNAt
3MHISt1
3MHISt3
4TMEABUTNt1
56DTHMtd
56DURAt
5HPETtd
ABT_Dt2
ABTD1
ACLYSt
ADPOHt
ALLTNt
C05463t2
C05957t
C06439td
CE1554t
EX_3mhis[e]
EX_56dthm[e]
EX_5HPET[e]
EX_aclys[e]
EX_adpoh[e]
EX_CE1554[e]
EX_hxcoa[e]
EX_lnlccrn[e]
EX_mev_R[e]
EX_mi1p_D[e]
HC00319t2
HXCOAte
LNLCCRNtd
MEV_Rt
OAAt
EX_3hpppn[e]
EX_5aop[e]
EX_alltn[e]
EX_CE2510[e]
EX_oaa[e]
3OCTDEC2CRNtr
DDECE1CRNtr
HDCECRNtr
ODECRNtr
TTDCRNtr
PACALDtm
2MOPtm
CE0328te
C181CPT2rbc
ETHAt
The
MK11t
MK7t
EX_mqn11[e]
EX_mqn7[e]
EX_acnam[e]
DM_mqn10[c]
F1Ate
sink_25hvitd2[c]
sink_Tyr_ggn[c]
sink_coa[c]
sink_gchola[c]
sink_lnlncacoa[c]
sink_odecoa[c]
sink_pydxn[c]
sink_stcoa[c]
sink_tag_hs[c]
sink_tchola[c]
sink_tdchola[c]
sink_vitd3[c]
sink_CE1273[c]
EX_glcn[e]
ADK1
EX_akg[e]
EX_dgsn[e]
EX_duri[e]
EX_h[e]
EX_h2o[e]
EX_o2[e]
EX_pi[e]
GALU
GAPD
NDPK4
NDPK6
PGK
PGMT
PIt6b
RNDR4
SPODM
r0345
NTD5
EX_nh4[e]
EX_fol[e]
LDH_L
PYK
TMDS
PYDXNtr
DCMPDA
ACALDt
2H3MVc
2H3MVte
EX_2h3mv[e]
3HIVAcm
3HIVActm
3HIVActe
EX_3hivac[e]
13_CIS_RETNte
EX_13_cis_retn[e]
GLCNte
C13856te
CE2172t
EX_CE2172[e]
NEU310e
NO2te
CATr
EX_gm1b_hs[e]
HMR_1373
HMR_1403
HMR_2374
HMR_2376
HMR_3520
HMR_6500
HMR_6611
HMR_6620
HMR_7431
HMR_7432
HMR_7617
HMR_7618
HMR_8418
HMR_9577
HMR_9578
HMR_9680
EX_sbt_D[e]
EX_nfd[e]
EX_nfdac[e]
EX_nfdlac[e]
EX_thrfvs[e]
IBUP_Rtdu
IBUP_Stdhep
LST4EXPthc
LSTNRATt
NFDACtep
NFDDMEThr
NFDLAChc
NFDLACtep
THRFVShc
THRFVStev
TMDOATPtsc
NFDNPYitr
NFDACitr
EX_C13856[e]
EX_M00010[e]
EX_M00017[e]
EX_M00021[e]
EX_M00260[e]
EX_M00265[e]
EX_M00315[e]
EX_M00341[e]
EX_M01207[e]
EX_M01235[e]
EX_M01582[e]
EX_M02053[e]
EX_M02457[e]
EX_M02613[e]
EX_M03045[e]
EX_M03051[e]
sink_ile_L[c]
sink_phe_L[c]
sink_thr_L[c]
sink_trp_L[c]
sink_val_L[c]
sink_arg_L[c]
sink_asn_L[c]
sink_asp_L[c]
sink_cys_L[c]
sink_glu_L[c]
sink_ser_L[c]
sink_tyr_L[c]
sink_gly[c]
DM_4glu56dihdind[c]
DM_kynate[c]
ATPS4mi
CYOR_u10mi
CYOOm2i`);
      setSelectedModel("Recon3D_VMH_large");
      setCheckboxes({ ...checkboxes, "bonferroni" : true});
  }

  const handleButtonClick = () => {
    setIsTrue((prevValue) => !prevValue);
  };

  const handleDownloadCSV = (list) => {
    if (isTrue) {
      //yeni hali
      //matched reactions array olabildigi icin hata olusabiliyodu 
      const csvHeader = data.column_names.map(col => `"${col}"`).join(";"); // Her sütunu çift tırnakla sar
      const csvRows = data.data.map((row) => {
        const newRow = row.map(cell => {
          if (typeof cell === 'object' && cell !== null && cell.hasOwnProperty('length')) {
            // Eğer hücre bir diziye benzerse (dizi olup olmadığını kontrol ediyoruz)
            return `"${cell.join(",")}"`;
          }
          // Diğer veriler için de çift tırnakla sarma işlemi yap
          return `"${cell}"`;
        });
        return newRow.join(";");
      });

      const csvData = csvHeader + "\n" + csvRows.join("\n");
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);

      const link = document.createElement("a");
      link.href = csvUrl;
      link.download = "data.csv";
      link.click();

      URL.revokeObjectURL(csvUrl);
    } else {
      const csvData =
        "Not Matched RX List" +
        "\n" +
        dataNotMatched.map((element) => [element]).join("\n");
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);

      const link = document.createElement("a");
      link.href = csvUrl;
      link.download = `Not Matched RXs.csv`;
      link.click();

      URL.revokeObjectURL(csvUrl);
    }
  };

  return (
    <div className="custom-page">
      <header className="header">
        <div className="logo-left">
          {" "}
          <div>
            <img src={rseaLogo} alt="rsea-logo" className="rseaLogoStyle"/>
          </div>
        </div>
        <span className="title">Reaction Set Enrichment Analysis</span>
        <div className="logo-right">
          {/* <div>
            <img
              src={teknofestLogo}
              alt="teknofest-logo"
              style={{ width: 140, height: "auto", marginRight: "3rem" }}
            />
          </div> */}
        </div>
      </header>

      <main className="content">
        <div className="first">
          <form onSubmit={handleSubmit}>
            <div className="flex-container">
              {/* input alma */}
              <div className="input-reactions">
                <Typography variant="h5" gutterBottom>
                  <b style={{fontSize : "1.4rem"}}>Input Reactions</b>
                </Typography>
                {/* input alma kutusu */}
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ ml: 2 }}>
                    {/* inputlarin text state'ine atanmasi (yazili girilen ifadeler icin) */}
                    <textarea
                      className="input-textbox"
                      value={text}
                      onChange={(event) => setText(event.target.value)}
                      rows={4}
                      placeholder="Paste a List"
                    />
                    <br></br>
                    {/* inputların text state'ine atanmasi (file olarak girilen ifadeler icin) */}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => fileInputRef1.current.click()}
                    >
                      <div style={{ fontSize: "10px", marginTop: "2px" }}>
                        Choose File
                      </div>
                    </Button>
                    <input
                      type="file"
                      ref={fileInputRef1}
                      onChange={(event) =>
                        uploadfileOnChange1(event.target.files[0])
                      }
                      style={{ display: "none" }}
                    />
                    {fileName1}
                  </Box>
                </Box>
              </div>
              {/* background alma */}
              <div className="background-reactions">
                <Typography variant="h5" gutterBottom>
                  <b className="bgRString">Background reaction list (optional)</b>
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ ml: 2 }}>
                    {/* yazili olarak alma */}
                    <textarea
                      className="input-textbox"
                      value={modelText}
                      onChange={(event) => setModelText(event.target.value)}
                      rows={4}
                      placeholder="Paste a List"
                    />
                    <br></br>
                    {/* dosya olarak alma */}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => fileInputRef2.current.click()}
                    >
                      <div style={{ fontSize: "10px", marginTop: "2px" }}>
                        Choose File
                      </div>
                    </Button>
                    <input
                      type="file"
                      ref={fileInputRef2}
                      onChange={(event) =>
                        uploadfileOnChange2(event.target.files[0])
                      }
                      style={{ display: "none" }}
                    />
                    {fileName2}
                  </Box>
                </Box>
              </div>
            </div>
            
            <Button variant="contained" color="success" sx={{ mt: 2 }} type="submit">
              Send
            </Button>
            {/* ornek liste ile ilgili kısım */}
            <br /><br /><br />
            <div className="ornekListe">
                Example reaction list of B memory cells (model : Recon3D_VMH_large) <br /> <br />
                <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleOrnekKopyalama()}
                    ><div style={{ fontSize: "10px", marginTop: "2px" }}>
                    Copy
                  </div>
                </Button>
            </div>
          </form>
          {/* loading sembolu */}
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openLoading}
              onClick={handleCloseLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
          {/* gelen verilerin dialogda gosterilmesi (dogru calistigi durum icin) */}
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            {data ? ( 
              isSuccess ? (
                <Alert
                  variant="outlined"
                  severity="success"
                  style={{ display: "inline-flex" }}
                >
                  Scan Successful !<br />
                  {inputCount} input reactions
                  <br />
                  {inputCount - dataNotMatched.length} matched reactions
                  <br />
                  {dataNotMatched.length} unmatched reactions
                </Alert>
              ) : (
                <Alert severity="error" style={{ display: "inline-flex" }}>
                  Scan is Not Successful!
                </Alert>
              )
            ) : (
              <Alert severity="warning" style={{ display: "inline-flex" }}>
                No matched reactions!
              </Alert>
            )}
          </Dialog>
          {/* gelen verilerin gosterilmesi (yanlis calistigi durum icin) */}
          <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
            <Alert severity="error" style={{ display: "inline-flex" }}>
              <div>{errorText}</div>
            </Alert>
          </Dialog>
        </div>
        {/* model name ve multiple testing kismini içeren kolon */}
        <div className="column-right">
          <Typography variant="h5" gutterBottom>
            <b>Model Name</b>
          </Typography>
              {/* modal name icin secenekler */}
          <FormControl sx={{ m: 1, marginTop: 0, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Select:</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedModel}
              label="model"
              onChange={handleModelChange}
            >
            
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"E.Coli (iAF1260)"}>E.Coli (iAF1260)</MenuItem>
              <MenuItem value={"Recon3D_BiGG"}>Recon3D_BiGG</MenuItem>
              <MenuItem value={"Recon3D_VMH"}>Recon3D_VMH</MenuItem>
              <MenuItem value={"Recon3D_VMH_large"}>Recon3D_VMH_large</MenuItem>
              <MenuItem value={"yeastGEM_v9.0.0"}>yeastGEM_v9.0.0</MenuItem>
              <MenuItem value={"Fruitfly-GEM"}>Fruitfly-GEM</MenuItem>
              <MenuItem value={"Mouse-GEM"}>Mouse-GEM</MenuItem>
              <MenuItem value={"Rat-GEM"}>Rat-GEM</MenuItem>
              <MenuItem value={"Worm-GEM"}>Worm-GEM</MenuItem>
              <MenuItem value={"Zebrafish-GEM"}>Zebrafish-GEM</MenuItem>

            </Select>
          </FormControl>

          <Typography variant="h5" gutterBottom>
            <b>Multiple Testing Options</b>
          </Typography>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.bonferroni || false}
                  onChange={handleCheckboxChange}
                  name="bonferroni"
                />
              }
              label="Bonferroni: One-step correction"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.sidak || false}
                  onChange={handleCheckboxChange}
                  name="sidak"
                />
              }
              label="Sidak: One-step correction"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes["holm-sidak"] || false}
                  onChange={handleCheckboxChange}
                  name="holm-sidak"
                />
              }
              label="Holm-Sidak: Step down method using Sidak adjustments"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.holm || false}
                  onChange={handleCheckboxChange}
                  name="holm"
                />
              }
              label="Holm: Step-down method using Bonferroni adjustments"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes["simes-hochberg"] || false}
                  onChange={handleCheckboxChange}
                  name="Simes-Hochberg"
                />
              }
              label="Simes-Hochberg: Step-up method  (independent)"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.hommel || false}
                  onChange={handleCheckboxChange}
                  name="hommel"
                />
              }
              label="Hommel: closed method based on Simes tests (non-negative)"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes["fdr_bh"] || false}
                  onChange={handleCheckboxChange}
                  name="fdr_bh"
                />
              }
              label="Fdr_bh: Benjamini/Hochberg  (non-negative)"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.fdr_by || false}
                  onChange={handleCheckboxChange}
                  name="fdr_by"
                />
              }
              label="Fdr_by: Benjamini/Yekutieli (negative)"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes["fdr_tsbh"] || false}
                  onChange={handleCheckboxChange}
                  name="fdr_tsbh"
                />
              }
              label="Fdr_tsbh: two stage fdr correction (non-negative)"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.fdr_tsbky || false}
                  onChange={handleCheckboxChange}
                  name="fdr_tsbky"
                />
              }
              label="Fdr_tsbky: two stage fdr correction (non-negative)"
            />
          </div>
          {/* Add more checkboxes as needed */}
        </div>
        {/* user instructions kismini içeren kolon */}
        <div className="column-right">
          <Typography variant="h5" gutterBottom>
            <b>User Instructions</b>
          </Typography>
          <Typography variant="body1">
            Model selection is important for the background reaction list. If
            you don't select a model or provide a background reaction list, the
            tool still works but all reactions in the KEGG generic reaction
            networks will be used as the background, and some significant
            pathways might lose.
            <br />
            <br />
            You can input a list of reaction identifiers line by line to ‘Paste
            a List’ box or upload a .csv or .txt file from your local computer.
            <br />
            <br />
            If a background reaction list is provided and a model name selected
            at the same time, the background reaction list is used for the
            analysis and the selected model is ignored.
            <br />
            <br />
            "If an input reaction identifier is not available in MetanetX, VMH, 
            or KEGG (or the model if selected), it is listed under 'Unmatched Reactions' 
            and is excluded from the analysis calculations."
          </Typography>
        </div>
      </main>
      {/* tablo ustundeki butonlar */}
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: data ? "flex" : "none",
        }}
      >
        <Button variant="contained" onClick={handleButtonClick}>
          {isTrue ? "Show unmatched reactions" : "Show result table"}
        </Button>
        <Button
          variant="contained"
          onClick={handleDownloadCSV}
          style={{
            marginLeft: "5px",
          }}
        >
          Download as CSV
        </Button>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Number of Matches</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {data ? (
              data.data[matchesIndex][4].map((element, index) => (
                <p style={{ textAlign: "center" }} key={index}>
                  {element}
                </p>
              ))
            ) : (
              <p>No data available.</p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => copyArrayToClipboard(data.data[matchesIndex][4])}
          >
            Copy to Clipboard
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* tablonun ustunde sayilarin gosterilmesi */}
      <div style={{ margin: "auto", display: isTrue ? "inline" : "none" }}>
        {data ? (
        <div className="sonuclarSayi">
          <p>input reactions :{inputCount}</p>
          <p>matched reactions :{dataNotMatched && <>{inputCount - dataNotMatched.length} </>}</p>
          <p>unmatched reactions :{dataNotMatched && <>{dataNotMatched.length} </>}</p>          
        </div>
        ):""}
      </div>
      
      {/* tablonun gosterilmesi */}
      <div style={{ margin: "auto", display: isTrue ? "inline" : "none" }} id="sonucKutusu">
        {data ? (
          <TableContainer sx={{ mt: 2 }} component={Paper}>
            <Table
              aria-label="custom pagination table"
              style={{
                width: "fit-content",
                maxWidth: "100%",
                boxShadow: "0 0 5px rgba(0,0,0,0.3)",
              }}
            >
              <TableHead>
                <TableRow>
                  {data.column_names.map((columnName, index) => {
                    if (
                      columnName === "kegg_link" ||
                      columnName === "Matched Reactions"
                    ) {
                      return null;
                    } else {
                      return (
                        <TableCell key={index} style={{ width: "min-content" }}>
                          {columnName}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? data.data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data.data
                ).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((columnValue, columnIndex) => {
                      if (
                        data.column_names[columnIndex] === "kegg_link" ||
                        data.column_names[columnIndex] === "Matched Reactions"
                      ) {
                        return null;
                      } else if (
                        data.column_names[columnIndex] === "Number of Matches"
                      ) {
                        return (
                          <TableCell
                            key={columnIndex}
                            style={{ width: "min-content" }}
                          >
                            <div>
                              <Button
                                variant="text"
                                onClick={handleClickOpen("paper", rowIndex)}
                              >
                                {columnValue}
                              </Button>
                            </div>
                          </TableCell>
                        );
                      } else if (
                        data.column_names[columnIndex] === "Pathway Name"
                      ) {
                        return (
                          <TableCell
                            key={columnIndex}
                            style={{ width: "20%", maxWidth: "300px" }}
                          >
                            <Button
                              variant="text"
                              href={row[data.column_names.indexOf("kegg_link")]}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {columnValue}
                            </Button>
                          </TableCell>
                        );
                      } else if (
                        data.column_names[columnIndex] != "Index" &&
                        data.column_names[columnIndex] != "Pathway ID" &&

                        data.column_names[columnIndex] !=
                          "Number of Reactions" &&
                        data.column_names[columnIndex] != "Pathway Name" &&
                        data.column_names[columnIndex] != "Number of Matches"
                      ) {
                        return (
                          <TableCell
                            key={columnIndex}
                            style={{ width: "min-content", minWidth: "50px" }}
                          >
                            {columnValue.toExponential(2)}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={columnIndex}
                            style={{ width: "min-content" }}
                          >
                            {columnValue}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableFooter
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  colSpan={3}
                  count={data.data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </TableContainer>
        ) : (
          <Typography variant="body1" mt={2}>
            {/* No data yet. Please upload a file. */}
          </Typography>
        )}
      </div>
      
      {/* eslesmeyen reaksiyonlarin gosterilmesi */}
      <div style={{ margin: "auto", display: isTrue ? "none" : "inline" }}>
        {data ? (
          <TableContainer sx={{ mt: 2 }}>
            <Table
              style={{
                width: "fit-content",
                maxWidth: "100%",
                boxShadow: "0 0 5px rgba(0,0,0,0.3)",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "min-content" }}>
                    Unmatched RX
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? dataNotMatched.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : dataNotMatched
                ).map((element, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ width: "min-content" }}>
                      {element}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    100,
                    { label: "All", value: -1 },
                  ]}
                  colSpan={3}
                  count={dataNotMatched.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </TableContainer>
        ) : (
          <Typography variant="body1" mt={2}>
            {/* No data yet. Please upload a file. */}
          </Typography>
        )}
      </div>

      <footer className="footer">
          <div className="footersDiv">
            <div>
            <span style={{ color: "red" }}><b>How to cite</b></span>
            <br></br><br></br>
           <br/>
           Cite as: Merve Yarıcı, Furkan Cantürk, Serdar Dursun, et al. RSEA: a web server for pathway enrichment analysis of metabolic reaction sets. Advance. September 30, 2024. <br />
           DOI: 10.22541/au.172767893.38375739/v1
            <br></br>
            </div>
            <div>
              <img src={LabLogo} alt="" className="lablogoStyle"/>
            </div>
          </div>
      </footer>
    </div>
  );
}

export default Homepage;

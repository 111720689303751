import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Homepage from './Homepage';
import App from './App';
import Result from './Result';

function Navigation() {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/result">Result</Link>
        </li>
      </ul>
    </nav>
  );
}

function Main() {
  return (
    <div>
      {//<Result></Result>
      }
      <Homepage />
    </div>
  );
}

export default Main;
